<template>
  <div class="relative">
    <div>
      <result-card class="grid grid-cols-2 gap-8" :doctorSearchRecords="doctorSearchRecords"></result-card>
    </div>
  </div>
</template>

<script>
import ResultCard from './ResultCard.vue'

export default {
  components: {
    ResultCard
  },
  data() {
    return {
      doctorSearchRecords: [
        {
          img: require('../assets/img/physician1.svg'),
          name: 'David M. Zientek, MD, FACC, FSCAI',
          worksAt: 'Seton Heart Institute - Medical Center South',
          amount: 300,
          field: 'Cardiologist',
          distanceAway: '5.2 mi away'
        },
        {
          img: require('../assets/img/physician2.svg'),
          name: 'Tuan D. Nguyen, MD',
          worksAt: 'Seton Heart Institute - Medical Park Tower',
          amount: 300,
          field: 'Cardiologist',
          distanceAway: '5.2 mi away'
        },
        {
          img: require('../assets/img/physician3.svg'),
          name: 'Tuan D. Nguyen, MD',
          worksAt: 'Seton Heart Institute - Medical Park Tower',
          amount: 300,
          field: 'Cardiologist',
          distanceAway: '5.2 mi away'
        },
        {
          img: require('../assets/img/physician1.svg'),
          name: 'David M. Zientek, MD, FACC, FSCAI',
          worksAt: 'Seton Heart Institute - Medical Park Tower',
          amount: 300,
          field: 'Cardiologist',
          distanceAway: '5.2 mi away'
        },
        {
          img: require('../assets/img/physician2.svg'),
          name: 'David M. Zientek, MD, FACC, FSCAI',
          worksAt: 'Seton Heart Institute - Medical Park Tower',
          amount: 300,
          field: 'Cardiologist',
          distanceAway: '5.2 mi away'
        },
        {
          img: require('../assets/img/physician3.svg'),
          name: 'Tuan D. Nguyen, MD',
          worksAt: 'Seton Heart Institute - Medical Park Tower',
          amount: 300,
          field: 'Cardiologist',
          distanceAway: '5.2 mi away'
        }
      ]
    }
  }
}
</script>

<style>

</style>