<template>
  <div>
    <div
      v-for="(item, index) in doctorSearchRecords"
      :key="index"
      class="flex card_rounded"
    >
      <img :src="item.img" alt="" class="card_rounded">
      <div class="ml-5 py-1">
        <p class="text-sm text-secondary">{{ item.worksAt }}</p>
        <p class="text-lg text-dark font-semibold">{{ item.name }}</p>
        <div class="flex justify-between items-center py-1">
          <span class="text-sm text-secondary">{{ item.field }}</span>
          <span class="text-sm text-secondary">{{ item.distanceAway }}</span>
        </div>
        <div class="flex justify-between items-center pt-3">
          <span class="text-sm text-dark">from ${{ item.amount }}/hr</span>
          <button
            class="bg-blue rounded-full text-white py-2 px-8"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    doctorSearchRecords: Array
  }
}
</script>

<style>

</style>