const specialties = [

  {
    "specialties": "Abdominal Radiology"
  },

  {
    "specialties": "Addiction Psychiatry"
  },

  {
    "specialties": "Adolescent Medicine"
  },

  {
    "specialties": "Adult Cardiothoracic Anesthesiology"
  },

  {
    "specialties": "Advanced Heart Failure and Transplant Cardiology"
  },

  {
    "specialties": "Anatomic and Clinical Pathology"
  },

  {
    "specialties": "Anesthesiology"
  },

  {
    "specialties": "Blood Banking-Transfusion Medicine"
  },

  {
    "specialties": "Cardiothoracic Radiology"
  },

  {
    "specialties": "Cardiovascular Disease"
  },

  {
    "specialties": "Chemical Pathology"
  },

  {
    "specialties": "Child Abuse Pediatrics"
  },

  {
    "specialties": "Child Neurology"
  },

  {
    "specialties": "Child and Adolescent Psychiatry"
  },

  {
    "specialties": "Clinical Cardiac Electrophysiology"
  },

  {
    "specialties": "Clinical Neurophysiology"
  },

  {
    "specialties": "Congenital Cardiac Surgery"
  },

  {
    "specialties": "Craniofacial Surgery"
  },

  {
    "specialties": "Critical Care Medicine"
  },

  {
    "specialties": "Cytopathology"
  },

  {
    "specialties": "Dermatology"
  },

  {
    "specialties": "Dermatopathology"
  },

  {
    "specialties": "Developmental and Behavioral Pediatrics"
  },

  {
    "specialties": "Diabetes"
  },

  {
    "specialties": "Diagnostic Radiology"
  },

  {
    "specialties": "Emergency Medicine"
  },

  {
    "specialties": "Endocrinology"
  },

  {
    "specialties": "Endovascular Surgical Neuroradiology"
  },

  {
    "specialties": "Family Medicine/Family Practice"
  },

  {
    "specialties": "Female Pelvic Medicine and Reconstructive Surgery"
  },

  {
    "specialties": "Forensic Pathology"
  },

  {
    "specialties": "Forensic Psychiatry"
  },

  {
    "specialties": "Gastroenterology"
  },

  {
    "specialties": "General Surgery"
  },

  {
    "specialties": "Geriatric Medicine"
  },

  {
    "specialties": "Geriatric Psychiatry"
  },

  {
    "specialties": "Hand Surgery"
  },

  {
    "specialties": "Hematology"
  },

  {
    "specialties": "Infectious Disease"
  },

  {
    "specialties": "Internal Medicine"
  },

  {
    "specialties": "Interventional Cardiology"
  },

  {
    "specialties": "Medical Biochemical Genetics"
  },

  {
    "specialties": "Medical Genetics and Genomics"
  },
  {
    "specialties": "Medical Microbiology Pathology"
  },

  {
    "specialties": "Medical Oncology"
  },

  {
    "specialties": "Medical Toxicology"
  },
  {
    "specialties": "Micrographic Surgery and Dermatologic Oncology"
  },

  {
    "specialties": "Molecular Genetic Pathology"
  },

  {
    "specialties": "Musculoskeletal Radiology"
  },

  {
    "specialties": "Neonatal-Perinatal Medicine"
  },

  {
    "specialties": "Nephrology"
  },

  {
    "specialties": "Neurology"
  },

  {
    "specialties": "Neuromuscular Medicine"
  },

  {
    "specialties": "Neuropathology"
  },

  {
    "specialties": "Neuroradiology"
  },

  {
    "specialties": "Neurotology"
  },

  {
    "specialties": "Nuclear Radiology"
  },

  {
    "specialties": "Obstetric Anesthesiology"
  },

  {
    "specialties": "Obstetrics and Gynecology"
  },

  {
    "specialties": "Ophthalmic Plastic and Reconstructive Surgery"
  },

  {
    "specialties": "Ophthalmology"
  },

  {
    "specialties": "Orthopaedic Sports Medicine"
  },

  {
    "specialties": "Orthopaedic Surgery"
  },

  {
    "specialties": "Otolaryngology-Head and Neck Surgery"
  },

  {
    "specialties": "Pain Medicine"
  },

  {
    "specialties": "Pediatric Anesthesiology"
  },

  {
    "specialties": "Pediatric Cardiology"
  },

  {
    "specialties": "Pediatric Critical Care Medicine"
  },

  {
    "specialties": "Pediatric Emergency Medicine"
  },

  {
    "specialties": "Pediatric Endocrinology"
  },

  {
    "specialties": "Pediatric Gastroenterology"
  },

  {
    "specialties": "Pediatric Hematology-Oncology"
  },

  {
    "specialties": "Pediatric Infectious Disease"
  },

  {
    "specialties": "Pediatric Nephrology"
  },

  {
    "specialties": "Pediatric Otolaryngology"
  },

  {
    "specialties": "Pediatric Pathology"
  },

  {
    "specialties": "Pediatric Pulmonology"
  },

  {
    "specialties": "Pediatric Radiology"
  },

  {
    "specialties": "Pediatric Rheumatology"
  },

  {
    "specialties": "Pediatric Sports Medicine"
  },

  {
    "specialties": "Pediatric Surgery"
  },

  {
    "specialties": "Pediatric Transplant Hepatology"
  },

  {
    "specialties": "Pediatric Urology"
  },

  {
    "specialties": "Pediatrics"
  },

  {
    "specialties": "Physical Medicine and Rehabilitation"
  },

  {
    "specialties": "Plastic Surgery"
  },

  {
    "specialties": "Psychiatry"
  },

  {
    "specialties": "Pulmonary Disease"
  },

  {
    "specialties": "Reproductive Endocrinology and Infertility"
  },

  {
    "specialties": "Rheumatology"
  },

  {
    "specialties": "Spinal Cord Injury Medicine"
  },

  {
    "specialties": "Sports Medicine"
  },

  {
    "specialties": "Surgical Critical Care"
  },

  {
    "specialties": "Thoracic Surgery"
  },

  {
    "specialties": "Transplant Hepatology"
  },

  {
    "specialties": "Urology"
  },

  {
    "specialties": "Vascular Surgery"
  },

  {
    "specialties": "Vascular and Interventional Radiology and Metabolism"
  }

]

export default specialties