<template>
  <div class="relative pt-20">
    <div class="flex flex-col justify-center items-center">
      <k-progress :percent="percent"></k-progress>
    </div>
    <div v-if="active === 1">
      <business-calendar
        @handleNext="handleNext"
      ></business-calendar>
    </div>
    <div v-if="active === 2">
      <Search @handleNext="handleNext" @handleBack="handleBack" />
    </div>
    <div v-if="active === 3">
      <Payment @handleNext="handleNext" @handleBack="handleBack" />
    </div>
    <div v-if="active === 4">
      <Confirm @handleNext="handleNext" @handleBack="handleBack" />
    </div>
  </div>
</template>

<script>
import BusinessCalendar from '../components/Calendar'
import Search from '../components/Search'
import Payment from '../components/Payment.vue'
import Confirm from '../components/Confirm.vue'

export default {
  components: {
    BusinessCalendar,
    Search,
    Payment,
    Confirm
  },
  data() {
    return {
      percent: 25,
      active: 1
    }
  },
  methods: {
    handleNext() {
      if(this.active ++ > 4)this.active = 1;
      this.percent = this.percent + 25;
    },
    handleBack() {
      if(this.active !== 1) {
        this.active = this.active - 1;
        this.percent = this.percent - 25;
      }
    }
  }
}
</script>

<style>
  .back_button {
    position: absolute;
    top: 10%;
    left: 3%;
  }
  .vc-arrows-container .vc-arrow {
    width: 3.25rem;
  }
  .vc-arrows-container .vc-arrow svg{
    margin: 0px !important;
  }

  #calendar-component .vc-title {
    padding: 16px 0px 0px;
  }

  #calendar-component .vc-popover-content-wrapper.is-interactive {
    transform: translate(182px, 54px);
    width: 290px;
  }
  #calendar-component .vc-popover-content-wrapper.is-interactive .vc-nav-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #calendar-component .vc-popover-content-wrapper.is-interactive .vc-nav-header .vc-nav-title.vc-grid-focus {
    width: 6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #calendar-component .vc-popover-content-wrapper.is-interactive .vc-nav-items span{
    width: 4.25rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -7px;
    top: -4px;
  }

  #calendar-component .vc-svg-icon {
    margin: 0px !important;
  }
</style>